<template>
    <van-cell-group title="操作" inset>
      <van-cell>
        <van-field
                v-model="packageCode"
                name="packageCode"
                label="箱号"
                placeholder="请扫描箱号二维码"
                required
                :rules="[{ required: true, message: '请扫描箱号二维码' }]"
                @focus="this.focusModel= 'packageCode'"
        ></van-field>
      <van-button round block type="primary" @click="this.show= true" style="height: 5.33333vw">
        扫描二维码
      </van-button>
      </van-cell>
    </van-cell-group>

    <QRReader
        v-model:show="show"
        @onRead="onRead1"
    />

    <div style="background-color: #fafafa">
        <GridList name="materialGrid" title="翻包物料" port-name="fr.warehouse.WareHouseMaterial.getWMByPackageCodes"
                  :request-data="{'rummage':'rummage','packIdList': this.$route.query.packIdList}"
                  :immediate-check=true
                  :dataList="dataList" style="margin: 4.26667vw;" ref="grid">
            <ListCard
                    v-for="item in dataList"
                    :key="item.id"
                    :id="item.id"
                    :material-i-d="item['material.id']"
            >
                <div class="item">
                    <div class="content">
                        <div class="tags fs16">{{"箱号：" + item['packageCode']}}</div>
                        <div class="tags fs16">{{"包装：" + item['pack.name']}}</div>
                        <div class="tags fs16">{{"容纳数：" + item['packQty']}}</div>
                        <div class="tags fs16">{{"当前数量：" + item['qty']}}</div>
                    </div>
                </div>

            </ListCard>
        </GridList>
    </div>
</template>

<script>
    import ListCard from "../../components/ListCard";
    import GridList from "../../components/GridList";
    import QRReader from "@/components/QRReader.vue";

    export default {
        name: "commitRummage",
        components: {ListCard, GridList,QRReader},
        created() {
            this.packIdList = this.$route.query.packIdList;
            this.getScanCode();
        },
        data() {
            return {
                packageCode: '',
                packIdList: [],
                dataList: [],
                focusModel:'',
              show:false
            }
        },
        methods: {
          onRead1(text) {
            var me=this;
              me.packageCode = text;
              var option = {
                portName: 'fr.warehouse.SeparatePack.rummagePack',
                data: {'packageCode': me.packageCode, 'packIdList': me.packIdList},
                needLoading: true,
                successCallback: function (data) {
                  me.$refs.grid.onRefresh();
                }
              };
              this.$sapi.callPort(option);
          },
            getScanCode() {
                if (!window.Capacitor) {
                    return;
                }
                var me = this;
                Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
                    var barcode = data.barcode;
                    console.log(data.barcode);
                    switch (me.focusModel) {
                        case 'packageCode' :
                            me.packageCode = barcode;
                            var option = {
                                portName: 'fr.warehouse.SeparatePack.rummagePack',
                                data: {'packageCode': me.packageCode, 'packIdList': me.packIdList},
                                needLoading: true,
                                successCallback: function (data) {
                                    me.$refs.grid.onRefresh();
                                }
                            };
                            this.$sapi.callPort(option);
                            break;
                    }
                })
            },
            commitRummage() {

            }
        }
    }
</script>

<style scoped>
    .item {
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        box-shadow: 0 0 2px #ddd;
        align-items: center;
        padding: 10px;
        margin: 2px;
    }

    .item + .item {
        margin-top: 10px;
    }

    .item > .content {
        flex: 1;
    }

    .item .time {
        color: #666;
    }
</style>
